<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card class="mt-10 mb-10" elevation="0">
          <v-data-table
            :headers="headers"
            :items="requestHouse"
            :search="search"
            class="elevation-1"
            style="background: #f5f5f5"
          >
            <template v-slot:top>
              <v-toolbar elevation="2" style="background: #f5f5f5" class="mt-2">
                <v-toolbar-title
                  style="margin-left: -14px"
                  class="mt-2 mb-2 ml-2"
                >
                  <v-select
                    v-model="trnasferob"
                    :items="AllWarehouseList"
                    item-text="name"
                    @change="TransferSelectInfo(trnasferob)"
                    return-object
                  ></v-select>
                </v-toolbar-title>

                <v-spacer></v-spacer>
                <div>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    filled
                    rounded
                    dense
                    hide-details
                  ></v-text-field>
                </div>

                <!-- <div>
                  <v-btn text @click="print(select)" class="ml-10">
                    <v-icon large color="black">mdi-printer </v-icon>
                  </v-btn>
                </div> -->
                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar>
            </template>

            <v-spacer> </v-spacer>
            <!-- 
            <template v-slot:item.link="{ item }">
              <v-btn shaped text @click="viewLink(item)" color="#FF7743">
                <v-icon class="mr-2" dark> mdi-link</v-icon>
                Link
              </v-btn>
            </template>
            <template v-slot:item.Invoice="{ item }">
              <v-btn
                color="primary"
                text
                @click="viewInvoice(item)"
                style="padding-left: 0px !important"
              >
                Invoice
              </v-btn>
            </template> -->

            <template v-slot:item.actions="{ item }">
              <v-btn
                color="primary"
                style="padding-left: 0px !important"
                text
                @click="ViewChalan(item)"
                >View Chalan</v-btn
              >
            </template>

            <template v-slot:item.dateaction="{ item }">
              <p class="mb-0">
                {{ new Date(item.date).toDateString() }}
              </p>
            </template>

            <template v-slot:item.leng="{ item }">
              <p class="mb-0">
                {{ item.transaction_ids.length }}
              </p>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
import { VueCropper } from "vue-cropper";

export default {
  data: () => ({
    crap: false,
    previews: {},

    show: true,
    fixed: true,

    menu: false,
    menu2: false,
    search: "",
    adddialog: false,
    editdialog: false,
    valid: false,
    snackbar: false,
    text: "",
    color: "",
    beforeselect: {},
    check: true,
    validForm: false,
    editProductForm: false,
    loading: true,
    trnasferob: {},
    AllWarehouseList: [],

    headers: [
      {
        text: "Chalan ID",
        align: "start",
        value: "id",
        sortable: false,
      },

      { text: "Date", value: "dateaction", sortable: false },
      //   { text: "Price ", value: "price_total", sortable: false },
      { text: " Status", value: "chalan_status", sortable: false },
      { text: "Items", value: "leng", sortable: false },
      //   { text: "Delivery Status", value: "delivery_status", sortable: false },
      //   { text: "View", value: "actions", sortable: false },
      //   { text: "Ref.No", value: "motherinvoice", sortable: false },
      // { text: 'test', value: 'tes' },
      { text: "", value: "actions", sortable: false },
    ],
    products: [],
    contact: {},
    selectoption: [],

    user: {},
    requestHouse: [],

    select: {},
    items: [
      { state: "All Products" },
      { state: "No Specification" },
      { state: "No Price" },
    ],
  }),

  components: {
    VueCropper,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  methods: {
    TransferSelectInfo(trnasferob) {
      axios
        .get(
          `manager/show_specific_manager_all_chalan/${this.trnasferob.flagvalue}/${this.trnasferob.id}/`
        )
        .then((response) => {
          this.requestHouse = response.data.data;
        });
    },

    initial() {
      axios.get(`merchant/all_merchant_managershops_warehouse_lists/${this.user.user_id}`).then((response) => {
        if (response.data.success) {
          this.AllWarehouseList = response.data.data;
          this.AllWarehouseList.forEach((element, index) => {
            element.flagvalue = element.value;
            element.value = index;
          });
        }
      });
    },
    viewInvoice() {},
    ViewChalan(item) {
      // console.log(item);
      let routeData = this.$router.resolve({
        name: `/merchant-managerdashboard/pages/chalan_report/${item.id}`,
      });
      routeData.href = `/merchant-managerdashboard/pages/chalan_report/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user && this.user.user_id) {
      this.initial();
    }
    this.TransferSelectInfo();
  },
};
</script>


<style>
.cut {
  width: 500px;
  height: 560px;
  margin: 30px auto;
  margin-top: 0px;
}

.c-item {
  max-width: 800px;
  margin: 10px auto;
  margin-top: 20px;
}

.content {
  margin: auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.cropper {
  height: 600px;
  background: #ddd;
}

.cropper-view-box {
  outline: 1px solid red !important;
}

.thumbnailImage:hover {
  cursor: pointer;
}

.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

